import React from "react";
import BackgroundImage from "../views/BackgroundImage";

import DeveloperBackgroundImageSrc from "../img/tyler_cafe.jpg";
import ActorBackgroundImageSrc from "../img/once.jpg";
import WritingBackgroundImageSrc from "../img/tyler_profile.jpg";
function TylerAkinMenu() {
  return (
    <div className="tyler-akin-menu">
      <BackgroundImage
        src={WritingBackgroundImageSrc}
        style={{ width: 100 + "%" }}
      />

      <div>
        <ul className="tyler-akin-menu-links">
          <li className="tyler-akin-menu-link">
            {/* <BackgroundImage
              src={DeveloperBackgroundImageSrc}
              style={{ width: 100 + "%" }}
            /> */}
            <a href="https://developer.tylerakin.com">Developer</a>
          </li>
          <li className="tyler-akin-menu-link">
            {/* <BackgroundImage
              src={ActorBackgroundImageSrc}
              style={{ width: 150 + "%" }}
            /> */}
            <a href="https://actor.tylerakin.com">Actor</a>
          </li>
          <li className="tyler-akin-menu-link">
            {/* <BackgroundImage
              src={WritingBackgroundImageSrc}
              style={{ width: 200 + "%" }}
            /> */}
            <a href="https://writing.tylerakin.com">Writer</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TylerAkinMenu;
