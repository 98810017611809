import React from "react";
import logo from "./logo.svg";
import WelcomeLoadingScreen from "./views/WelcomeLoadingScreen";
import TylerAkinMenu from "./views/TylerAkinMenu";
import "normalize.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <WelcomeLoadingScreen />
      <TylerAkinMenu />
    </div>
  );
}

export default App;
