import React from "react";

function WelcomeLoadingScreen() {
  return (
    <div className="welcome-loading-screen">
      {/* Hello World. Life's not quite so romantic as coming of age tale. But it is real. And it is enough. */}
    </div>
  );
}

export default WelcomeLoadingScreen;
